<template >
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha" style="vertical-align:middle !important">
          Minha Conta
        </h2>
      </div>
      <div class="col-md-2" >
        <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-escola'});"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div class="pm-card" v-if="perfil">

      <h4>{{perfil.usuario.nome}}</h4>
      <p>{{perfil.email}}</p>

      <div class="p-input-filled" style="margin-top:10px">
        <pm-InputText placeholder="Digite a Nova Senha" type="password" style="width:350px;" v-model="senha" />
      </div>

      <div class="p-input-filled" style="margin-top:10px">
        <pm-InputText placeholder="Confirme a Nova Senha" type="password" style="width:350px;" v-model="senhaConfirme" />
      </div>
      <br>
      <pm-Button  type="button" @click="alterarSenha()"
        icon="pi pi-check"
        label="Alterar Senha"  class="p-button-sm p-button-danger btn-color">
      </pm-Button>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { LoginInfo } from "@/class/login.js";

export default defineComponent({
  props: {

  },
  data() {
    return {
      home: {icon: 'pi pi-home', to: ''},
      items_bread: [
        {label: 'Perfil'},
      ],
      perfil:null,
      senha:null,
    }
  },
  methods: {
    async alterarSenha(){
      if(this.senha == null){
        this.$vaToast.init({
            message: "Digite uma Senha!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
         return false;
      }

      if(this.senha.length < 6){
        this.$vaToast.init({
            message: "Digite uma Senha com 6 ou mais caracteres!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
         return false;
      }

      if(this.senhaConfirme == null){
        this.$vaToast.init({
            message: "Confirmação da Senha está Vazio!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
         return false;
      }

      if(this.senhaConfirme != this.senha){
        this.$vaToast.init({
            message: "As Senhas não são Idênticas!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
        return false;
      }

      const token = sessionStorage.getItem("token");
      const playload = {
        token: token,
        password: this.senha,
      }

      const dt = await LoginInfo.trocarSenhaPerfil(playload);
      this.senha = null;
      this.$vaToast.init({
        message: "Senha da sua Conta Alterada com sucesso!",
        iconClass: 'fa-star-o',
        position: 'top-right',
        duration: 3000,
        color: 'success',
        fullWidth: false,
      });

      this.senha = null;
      this.senhaConfirm = null;
    }
  },
  async beforeMount(){
    const token = sessionStorage.getItem("token");
    const dt = await LoginInfo.WhoIam(token);
    this.perfil = dt.data;
  }
});
</script>
